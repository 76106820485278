import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense, Fragment } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import TagManager from "react-gtm-module";
TagManager.initialize({
  gtmId: "GTM-KFQ7ZN3F",
});


ReactDOM.render(
  <React.StrictMode>
    <Fragment>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </Fragment>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
