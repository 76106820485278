import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import LoaderSuspense from "./components/LoaderSuspense";
import "./assets/designsystemlib/docs/web.v1.0/js/popper/popper.min.js";
import "./assets/designsystemlib/docs/web.v1.0/sources/js/index.umd.js";
import "./assets/designsystemlib/docs/web.v1.0/css/axaDesignSystemFonts.min.css";
import "./assets/designsystemlib/docs/web.v1.0/css/axaIconFont.min.css";
import "./assets/designsystemlib/docs/web.v1.0/sources/scss/axaDesignSystemLib.scss";
import "./assets/css/style.css";
const Homepage = React.lazy(() => import("./pages/Homepage"));
const Notfound = React.lazy(() => import("./pages/Notfound"));
const DevelopmentHome = React.lazy(() =>
  import("./pages/development/DevelopmentHome")
);
const DevelopmentArticles = React.lazy(() =>
  import("./pages/development/Articles")
);
const Highlight = React.lazy(() => import("./pages/highlight/Highlight"));
const Contact = React.lazy(() => import("./pages/Contact"));
const DesignHome = React.lazy(() => import("./pages/design/Home"));
const Article = React.lazy(() => import("./pages/Article"));
const DesignArticles = React.lazy(() => import("./pages/design/Articles"));
const Checklist = React.lazy(() => import("./pages/design/Checklist") )
const LastReleaseDesign = React.lazy(() =>
  import("./pages/design/LastRelease")
);
const LastReleaseDev = React.lazy(() =>
  import("./pages/development/LastRelease")
);
const News = React.lazy(() => import("./pages/homepage/News"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const Legal = React.lazy(() => import("./pages/Legal"));
const Preview = React.lazy(() => import("./pages/Preview"));
const Header = React.lazy(() => import("./components/Header"));

function App() {
  return (
    <React.Suspense
      fallback={
        <div>
          <LoaderSuspense></LoaderSuspense>
        </div>
      }
    >
      <BrowserRouter>
        <Header></Header>
        <div className="navbar-mask"></div>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/development" element={<DevelopmentHome />} />
          <Route path="/insight" element={<Highlight />} />
          <Route path="/contact" element={<Contact />} />
          <Route exact path="/design" element={<DesignHome />} />
          <Route
            exact
            path="/design/last_release"
            element={<LastReleaseDesign />}
          />
          <Route
            exact
            path="/development/last_release"
            element={<LastReleaseDev />}
          />
          <Route path="/development/:id" element={<DevelopmentArticles />} />
          <Route path="/design/:id" element={<DesignArticles />} />
          <Route path="/design/checklist" element={<Checklist />} />
          <Route path="/article/:id" element={<Article />} />
          <Route path="/news" element={<News />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/preview" element={<Preview />} />
          <Route element={<Notfound />} />
        </Routes>
      </BrowserRouter>
    </React.Suspense>
  );
}

export default App;
